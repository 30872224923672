const isProd = process.env.NODE_ENV === 'production'

// TODO: improve SSR detect by request domain
const isInIframe = process.client ? window.self !== window.top : false

export function useDetect() {
  return {
    isProd,
    isInIframe,
  }
}
