import type { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  scrollBehavior(to, _, savedPosition) {
    if (to.query.scroll !== undefined) {
      return
    }

    // Scroll to heading on click

    if (to.hash) {
      setTimeout(() => {
        try {
          const heading = document.querySelector(to.hash) as HTMLElement
          return window.scrollTo({
            top: heading ? heading.offsetTop : 0,
            behavior: 'smooth',
          })
        } catch (error: any) {
          console.log('invalid hash', to.hash)
        }
      })
      return
    }

    const nuxtApp = useNuxtApp()

    if (savedPosition) {
      // Handle Suspense resolution
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce('page:finish', () => {
          resolve(savedPosition)
        })
      })
    }

    return { top: 0 }
  },
}
