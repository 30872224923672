import { defineStore } from 'pinia'
import { FetchError } from 'ofetch'
import { useAuth } from './auth'
import type { UserProfile } from '~/models/users'

interface UserState {
  user: UserProfile | null
}

export const useUsers = defineStore('users', () => {
  const authStore = useAuth()
  const runtimeConfig = useRuntimeConfig()
  const baseURL = runtimeConfig.public.gatewayApi

  const state = reactive<UserState>({
    user: null,
  })

  const reloadUser = async () => {
    if (!state.user) {
      return
    }
    const response = await useAuthFetch<{ data: UserProfile }>(
      `/users/${state.user.id}`,
      {
        baseURL,
      }
    )
    state.user = response.data
  }

  const fetchUser = async (id?: number) => {
    if (
      state.user &&
      (state.user.id === id ||
        (id === undefined && state.user.id === authStore.profile?.id)) // or me loaded
    ) {
      return
    }
    if (id !== undefined && isNaN(Number(id))) {
      throw useCreateScreenError({ message: 'incorrect user id' })
    }

    state.user = null

    try {
      const response = await useAuthFetch<{ data: UserProfile }>(
        `/users/${id ?? 'me'}`,
        {
          baseURL,
        }
      )
      state.user = response.data
    } catch (error: any) {
      if ((error as FetchError).status === 404) {
        throw useCreateScreenError({ message: 'no such user' })
      }
    }
  }

  const getUser = async (id?: number) => {
    const response = await useAuthFetch<{ data: UserProfile }>(
      `/users/${id ?? 'me'}`,
      {
        baseURL,
      }
    )
    return response.data
  }

  return {
    user: toRef(state, 'user'),
    isMe: computed(() => state.user?.id === authStore.profile?.id),
    fetchUser,
    getUser,
    reloadUser,
  }
})
