import type { ListMeta } from '~/models/list'

export type InfinityListParams = Record<
  string,
  string | number | string[] | number[] | undefined
>

export interface InfinityList<T> {
  endpoint: string
  baseURL: string
  hits: T[]
  data: T[]
  meta: null | ListMeta
  loaded: boolean
  processing: boolean
  params: InfinityListParams
}

interface ListResponse<T> {
  data: T[]
  meta: ListMeta
}

export default function <T>(
  endpoint: string,
  baseURL: string,
  prefetchCallback?: (params: InfinityList<T>['params']) => void,
  filterCallback?: (data: T[]) => T[],
  headersCallback?: () => { [key: string]: string }
) {
  const state = reactive<InfinityList<T>>({
    endpoint,
    baseURL,
    hits: [],
    data: [],
    processing: false,
    loaded: false,
    meta: null,
    params: {
      page: 1,
    },
  })

  const allRecordsLoaded = computed(
    () => state.meta?.current_page === state.meta?.last_page
  )

  async function nextPage() {
    state.params.page = (state.meta?.current_page || 0) + 1
    await fetch()
    state.hits = [...state.hits, ...state.data]
  }

  async function getPage(page?: number) {
    if (state.loaded && !page) {
      return
    }
    state.params.page = page || 1
    await fetch()
    state.hits = [...state.data]
  }

  async function fetch() {
    prefetchCallback && prefetchCallback(state.params)
    state.processing = true
    const { data, meta } = await useAuthFetch<ListResponse<T>>(state.endpoint, {
      ...(headersCallback && { headers: headersCallback() }),
      baseURL: state.baseURL,
      params: state.params,
    })

    // @ts-ignore-next-line it should be fine, but would be nice to fixe typing here
    state.data =
      typeof filterCallback === 'function' ? filterCallback(data) : data
    state.meta = meta
    state.processing = false
    state.loaded = true
  }

  return {
    state,
    fetch,
    reset: () => {
      state.hits = []
      state.data = []
      state.meta = null
      state.loaded = false
      state.params.page = 1
    },
    getPage,
    nextPage,
    allRecordsLoaded,
  }
}
