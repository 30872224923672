import { createMetrika } from './helpers'

export default defineNuxtPlugin(() => {
  const router = useRouter()
  const tag = useRuntimeConfig().public.metrikaTag

  if (!tag || useDetect().isInIframe) {
    return
  }

  async function initMetrika() {
    try {
      const metrika = await createMetrika(tag)

      router.afterEach((to, from) => {
        metrika('hit', to.path, { referer: from.path })
      })
    } catch (error: any) {
      console.warn('error on create metrika script', error)
    }
  }

  initMetrika()
})
