import { marked } from 'marked'
import { defineNuxtPlugin } from '#app'

marked.setOptions({
  breaks: true,
})

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('marked', marked)
})

declare module '#app' {
  interface NuxtApp {
    $marked: typeof marked
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $marked: typeof marked
  }
}
