import type { CookieOptions } from 'nuxt/dist/app/composables/cookie'

// we crucially rely on auth cookie in storages, but defineStore can't be created in async manner,
// async wrappers aroud defineStore maybe be possible but have another issues
// so we run async load method (in the global middleware) before define of auth store
// in all cases except native app we should get the same CookieRef as before
// lang cookie uses in async ready setups so we can directly use async load to get ref

export function useAppCookie<T>(
  key: string,
  params?: CookieOptions<T> & { readonly?: false | undefined }
) {
  const cookie = useCookie<T>(key, params)

  function load() {
    if (cookie.value !== undefined) {
      return cookie
    }
  }

  function get() {
    if (cookie.value === undefined) {
      throw new Error(`cookie ${key} using before loading`)
    }
    return cookie
  }

  return {
    cookie,
    load,
    get,
  }
}
