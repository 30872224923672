import type { AuthData } from '~/models/auth'

export function useAuthCookie() {
  const { load, cookie } = useAppCookie<AuthData | null>('gateway-auth', {
    maxAge: 2 * 365 * 24 * 60 * 60,
    domain: useDomain(),
  })

  return { load, cookie }
}
