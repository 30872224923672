import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'
import minMax from 'dayjs/plugin/minMax'
import ruLocale from 'dayjs/locale/ru'
import zhLocale from 'dayjs/locale/zh'

export default defineNuxtPlugin((nuxtApp) => {
  const { locale } = nuxtApp.$i18n as any

  dayjs.extend(localizedFormat)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(isToday)
  dayjs.extend(relativeTime)
  dayjs.extend(minMax)
  dayjs.extend(duration)

  // we import locales like this to avoid issue with locales import in SSR in production build
  dayjs.locale(ruLocale)
  dayjs.locale(zhLocale)
  dayjs.locale(locale.value || 'en')

  dayjs.extend(updateLocale)

  nuxtApp.provide('dayjs', dayjs)
})

declare module '#app' {
  interface NuxtApp {
    $dayjs: typeof dayjs
  }
}
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $dayjs: typeof dayjs
  }
}
