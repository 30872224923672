import { defineStore } from 'pinia'
import { transformProfile } from '~/models/users'
import type {
  UserBasicData,
  UserProfile,
  UserProfileUpdate,
} from '~/models/users'
import type { AuthData } from '~/models/auth'

interface AuthState {
  profile: UserProfile | null
  isLoginModalOpen: boolean
}

export const useAuth = defineStore('auth', () => {
  const runtimeConfig = useRuntimeConfig() // executing useRuntimeConfig inside catch causes nuxt app instance unavailable in SSR mode
  const baseURL = runtimeConfig.public.gatewayApi
  const { cookie: authCookie } = useAuthCookie()
  console.log('auth: init auth cookie', authCookie.value)

  watch(
    authCookie,
    (newValue, oldValue) => {
      console.log(
        'auth: cookie value changed',
        JSON.stringify(newValue),
        JSON.stringify(oldValue)
      )
    },
    { deep: true }
  )

  const state = reactive<AuthState>({
    profile: null,
    isLoginModalOpen: false,
  })

  const login = (auth: AuthData) => {
    console.log('auth: login', auth)
    authCookie.value = auth
    console.log('auth: set authCookie.value', authCookie.value)
  }

  const logout = async () => {
    console.log('auth: logout')
    authCookie.value = null
    console.log('auth: set authCookie to null', authCookie.value)
    state.profile = null
    if (process.client) {
      await nextTick()
      window.location.reload()
    }
    // navigateTo('/')
  }

  const authWithPassword = async (email: string, password: string) => {
    const response = await useAuthFetch<AuthData>('/oauth/token', {
      baseURL,
      method: 'POST',
      body: {
        email,
        password,
      },
    })

    console.log('auth: auth with password')
    await login(response)
  }

  const requestMagic = async (email: string, redirect?: string) => {
    await useAuthFetch<{ data: any }>('/register/magic', {
      baseURL,
      method: 'POST',
      body: {
        email,
        ...(redirect && { redirect: encodeURIComponent(redirect) }),
        // wnhub app to enable magic code for mobile app
        app: 'wnhub_general',
      },
    })
  }

  const authWithMagic = async (code: string) => {
    const response = await useAuthFetch<AuthData>('/oauth/token/magic', {
      baseURL,
      method: 'POST',
      body: {
        code,
      },
    })

    console.log('auth: auth with magic')
    await login(response)
  }

  const authWithMagicCode = async (body: { email: string; code: string }) => {
    const response = await useAuthFetch<AuthData>('/oauth/token/magic-code', {
      baseURL,
      method: 'POST',
      body,
    })

    console.log('auth: auth with magic code')
    await login(response)
  }

  // On SSR we have to ensure that all components will have profile data without extra requests
  let fetchProfilePromise: null | Promise<undefined> = null
  const fetchProfile = () => {
    if (state.profile) {
      return
    }
    if (fetchProfilePromise) {
      return fetchProfilePromise
    }

    fetchProfilePromise = new Promise((resolve, reject) => {
      ;(async () => {
        try {
          const { data } = await useAuthFetch<{ data: UserProfile }>(
            `/users/me`,
            {
              baseURL,
            }
          )

          state.profile = transformProfile(data)

          resolve(undefined)
        } catch (error: any) {
          reject(error)
        } finally {
          fetchProfilePromise = null
        }
      })()
    })

    return fetchProfilePromise
  }

  const updateProfile = async (userData: UserProfileUpdate) => {
    const response = await useAuthFetch<{ data: UserProfile }>(`/users/me`, {
      baseURL,
      method: 'PUT',
      body: userData,
    })

    state.profile = transformProfile(response.data)
  }

  const refreshProfile = async () => {
    const { data } = await useAuthFetch<{ data: UserProfile }>(`/users/me`, {
      baseURL,
    })

    state.profile = transformProfile(data)
  }

  const uploadMedia = async (
    file: File,
    category?: 'image' | 'logo' | 'background'
  ) => {
    const formData = new FormData()

    formData.append('category', category || 'logo')
    formData.append('media', file)

    const response = await useAuthFetch<{ data: UserProfile }>(
      `/users/me/media`,
      {
        baseURL,
        method: 'POST',
        body: formData,
      }
    )

    state.profile = response.data
  }

  const refreshToken = async () => {
    console.log('auth: refresh tocketn with', authCookie.value?.refresh_token)
    if (!authCookie.value || !authCookie.value.refresh_token) {
      console.log('auth: no refresh token')
      throw new Error('No refresh token')
    }

    const response = await useKrakenFetch<AuthData>('/oauth/token/refresh', {
      baseURL,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authCookie.value?.refresh_token}`,
      },
    })

    authCookie.value = response
    console.log('auth: refreshed to', response)
  }

  const openLoginModal = () => {
    state.isLoginModalOpen = true
  }

  const closeLoginModal = () => {
    state.isLoginModalOpen = false
  }

  const currentUserBasicData: ComputedRef<UserBasicData | null> = computed(
    () => {
      if (!state.profile) {
        return null
      } else {
        return {
          id: state.profile?.id,
          name: getFullName(state.profile) as string,
          media: getAvatar(state.profile),
          is_subscription_active: state.profile.is_subscription_active,
        }
      }
    }
  )

  return {
    auth: readonly(authCookie),
    profile: toRef(state, 'profile'),
    isLoginModalOpen: toRef(state, 'isLoginModalOpen'),
    userId: computed(() => state.profile?.id),
    ticket: computed(() => state.profile?.ticket || 'Community'),
    isProfileCompleted: computed(
      () => state.profile?.first_name && state.profile?.last_name
    ),
    isAdmin: computed(() =>
      state.profile && state.profile.roles
        ? state.profile.roles.includes('admin') ||
          state.profile.roles.includes('super_admin')
        : false
    ),
    isProActive: computed(() => !!state.profile?.is_subscription_active),
    uploadMedia,
    authWithPassword,
    fetchProfile,
    refreshProfile,
    authWithMagic,
    authWithMagicCode,
    requestMagic,
    refreshToken,
    logout,
    openLoginModal,
    closeLoginModal,
    updateProfile,
    currentUserBasicData,
  }
})
