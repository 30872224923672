import { FetchError } from 'ofetch'
import type { NitroFetchOptions } from 'nuxt/node_modules/nitropack'
import { useCurrentLang } from '~/stores/current-lang'

const LANG_HEADER = 'Accept-Language'

interface KrakenError {
  error_backend_alias: {
    http_status_code?: number
    http_body?: string
  }
}

function throwKrakenError(response: any, method = 'GET', request: string) {
  if (
    typeof response === 'object' &&
    response !== null &&
    'error_backend_alias' in response
  ) {
    const krakenError = response as KrakenError
    const code = krakenError.error_backend_alias?.http_status_code || 500
    const error = new FetchError(
      `${code} Kraken Wrapped Error (${method}, ${request})`
    )
    error.status = code
    error.statusCode = code

    try {
      if (krakenError.error_backend_alias.http_body) {
        error.data = JSON.parse(krakenError.error_backend_alias.http_body)
      }
    } catch (error: any) {
      console.warn('Error on parse Kraken error data', error)
    }

    throw error
  }
}

export const useKrakenFetch = async <T>(
  request: string,
  opts?: NitroFetchOptions<any> | undefined
) => {
  const { lang } = useCurrentLang()
  const targetHeader = LANG_HEADER.toLowerCase()

  const hasLangHeader = Object.keys(opts?.headers || []).some(
    (header) => header.toLowerCase() === targetHeader
  )

  if (!hasLangHeader && lang) {
    opts ??= {}
    opts.headers ??= {}
    opts.headers = {
      ...opts.headers,
      ...{ [LANG_HEADER]: lang },
    }
  }

  try {
    const response = await $fetch<T>(request, opts)
    throwKrakenError(response, opts?.method, request)

    return response
  } catch (error: any) {
    throwKrakenError(error.data, opts?.method, request)

    throw error
  }
}
