<template>
  <div>
    <NuxtLayout>
      <!-- <NuxtLoadingIndicator color="#0D65D8" :height="2" /> -->
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import './patch-server-log'
import { useI18n } from 'vue-i18n'
import { setLocale } from '@vee-validate/i18n'
import { useAuth } from '~/stores/auth'
import { useUsers } from '~/stores/users'
import { useCurrentLang } from '~/stores/current-lang'

const { $dayjs } = useNuxtApp()
const { locale, t } = useI18n()
const { setLang } = useCurrentLang()
const authStore = useAuth()
const usersStore = useUsers()

// we want to check and load profile here as sometimes we need failed to load it from middleware
if (authStore.auth && !authStore.profile) {
  await authStore.fetchProfile()
}

setLang(locale.value)

watch(
  () => locale.value,
  async () => {
    setLang(locale.value)
    $dayjs.locale(locale.value)
    setLocale(locale.value)
    await usersStore.reloadUser()
  }
)

useHeadMeta({
  title: () => t('head_meta.default_title'),
  description: () => t('head_meta.default_description'),
  keywords: () => t('head_meta.default_keywords'),
  image: '/og-image.png',
})
</script>
