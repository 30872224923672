import type { ConfigInterface } from '~core/models/config'
import type { Media } from './media'
import { enumToOptions, optionsToMap } from '~/utils/options-utils'

export enum EventStatus {
  Draft = 'draft',
  Disabled = 'disabled',
  Open = 'open',
}

export enum EventType {
  Default,
  Course,
}

export enum EventSubtype {
  Conference,
  Connect,
  'CEO Summit',
  Other,
}

export const EventSubtypeOptions = enumToOptions(EventSubtype, true)
export const EventSubtypeMap = optionsToMap(EventSubtypeOptions)

export enum EventRegion {
  Europe,
  APAC,
  LATAM,
  MENA,
  NORAM,
  AFRICA,
  MEAST,
  CIS,
}

export const EventRegionOptions = enumToOptions(EventRegion, true)
export const EventRegionMap = optionsToMap(EventRegionOptions)

export interface EventLite {
  id: number
  slug: string
  name: string
  city: string | null
  tagline: string | null
  timezone: string
  logo: Media | null
  date_from: string
  date_to: string
  hub_date_from: string | null
  hub_date_to: string | null
  custom_date: string | null
  is_online: boolean
  is_onsite: boolean
  auto_reg: number | null
  active: boolean
  address: string | null
  address_link: string | null
  is_has_ticket?: boolean
  preview_description: string
  status: EventStatus
  type: EventType
}

export interface Event extends EventLite {
  config: ConfigInterface
  latitude: number | null
  longitude: number | null
  host?: string
  background: Media | null
  description: string | null
}

export const EventsListTypeMap = {
  Past: 'past',
  Upcoming: 'upcoming',
  Me: 'me',
  MePast: 'me/past',
  MeUpcoming: 'me/upcoming',
} as const

export type EventsListType =
  (typeof EventsListTypeMap)[keyof typeof EventsListTypeMap]

export const EventTypeRoutesMap = {
  [EventType.Default]: 'event-info',
  [EventType.Course]: 'academy',
}

export const EventTypePluralNamesMap = {
  [EventType.Default]: 'events',
  [EventType.Course]: 'courses',
}

export const EventTypeNamesMap = {
  [EventType.Default]: 'event',
  [EventType.Course]: 'course',
}
