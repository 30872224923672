import { default as indexFCOxRvHzckMeta } from "/builds/sites/wnhub-general-client/pages/[url]-page/index.vue?macro=true";
import { default as indextMKJLElIVzMeta } from "/builds/sites/wnhub-general-client/pages/academy/[event]/index.vue?macro=true";
import { default as about97LLlKQi3mMeta } from "/builds/sites/wnhub-general-client/pages/academy/index/about.vue?macro=true";
import { default as indexvIIQDOO91TMeta } from "/builds/sites/wnhub-general-client/pages/academy/index/index.vue?macro=true";
import { default as myi71dIfp5b7Meta } from "/builds/sites/wnhub-general-client/pages/academy/index/my.vue?macro=true";
import { default as pastwNkzrMG2MPMeta } from "/builds/sites/wnhub-general-client/pages/academy/index/past.vue?macro=true";
import { default as indexUeUqjzPTRjMeta } from "/builds/sites/wnhub-general-client/pages/academy/index.vue?macro=true";
import { default as _91code_93CTzcha3GaHMeta } from "/builds/sites/wnhub-general-client/pages/auth/magic/[code].vue?macro=true";
import { default as indexBf0gpAyrQLMeta } from "/builds/sites/wnhub-general-client/pages/cart/[event]/index.vue?macro=true";
import { default as contactsT7Avz26Jg4Meta } from "/builds/sites/wnhub-general-client/pages/contacts.vue?macro=true";
import { default as indexqOma72b19BMeta } from "/builds/sites/wnhub-general-client/pages/event-info/[event]/index.vue?macro=true";
import { default as _91slug_93YKXWezcl9iMeta } from "/builds/sites/wnhub-general-client/pages/event-info/[event]/showcase/[slug].vue?macro=true";
import { default as indexAMsIYRm4yWMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index/index.vue?macro=true";
import { default as myYf7WTaaQdjMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index/my.vue?macro=true";
import { default as pastRCrboUPMmdMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index/past.vue?macro=true";
import { default as indexC47lifGnVYMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index.vue?macro=true";
import { default as _91id_93nwo1ITHx9MMeta } from "/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/blocks/[id].vue?macro=true";
import { default as companies_45buttonCqvRtyl4tHMeta } from "/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-button.vue?macro=true";
import { default as companies_45tableVzoq5VWmDKMeta } from "/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-table.vue?macro=true";
import { default as indexQkzLiatZ3LMeta } from "/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/index.vue?macro=true";
import { default as indexQ3gIjCZ8TiMeta } from "/builds/sites/wnhub-general-client/pages/index/[category]/index.vue?macro=true";
import { default as popularpLjQCk3nFTMeta } from "/builds/sites/wnhub-general-client/pages/index/[category]/popular.vue?macro=true";
import { default as premiumnXKzkYeRFoMeta } from "/builds/sites/wnhub-general-client/pages/index/[category]/premium.vue?macro=true";
import { default as _91category_93aVdB5jMjM0Meta } from "/builds/sites/wnhub-general-client/pages/index/[category].vue?macro=true";
import { default as indexZRmoZ1CADzMeta } from "/builds/sites/wnhub-general-client/pages/index/index.vue?macro=true";
import { default as popularji6uGXYG32Meta } from "/builds/sites/wnhub-general-client/pages/index/popular.vue?macro=true";
import { default as premiumkPwtzaXvyqMeta } from "/builds/sites/wnhub-general-client/pages/index/premium.vue?macro=true";
import { default as indexFmwB729zM7Meta } from "/builds/sites/wnhub-general-client/pages/index.vue?macro=true";
import { default as index8bftAv4JtkMeta } from "/builds/sites/wnhub-general-client/pages/news/[category]/index.vue?macro=true";
import { default as item_45_91id_93yGPN88Q2q1Meta } from "/builds/sites/wnhub-general-client/pages/news/[category]/item-[id].vue?macro=true";
import { default as popularc2hRwihuY3Meta } from "/builds/sites/wnhub-general-client/pages/news/[category]/popular.vue?macro=true";
import { default as premiumE5T9S1DxDHMeta } from "/builds/sites/wnhub-general-client/pages/news/[category]/premium.vue?macro=true";
import { default as _91category_9324I41V4sYiMeta } from "/builds/sites/wnhub-general-client/pages/news/[category].vue?macro=true";
import { default as indexCA62VUbi3xMeta } from "/builds/sites/wnhub-general-client/pages/news/index.vue?macro=true";
import { default as popularZhHeGoyDWIMeta } from "/builds/sites/wnhub-general-client/pages/news/popular.vue?macro=true";
import { default as premiumlv8SRVLlYZMeta } from "/builds/sites/wnhub-general-client/pages/news/premium.vue?macro=true";
import { default as newscWS5WuBcNCMeta } from "/builds/sites/wnhub-general-client/pages/news.vue?macro=true";
import { default as privacy_45policyPreT20nEW6Meta } from "/builds/sites/wnhub-general-client/pages/privacy-policy.vue?macro=true";
import { default as pro0BKHPgHHJWMeta } from "/builds/sites/wnhub-general-client/pages/pro.vue?macro=true";
import { default as indexzpUTVcJJVkMeta } from "/builds/sites/wnhub-general-client/pages/questions/[category]/index.vue?macro=true";
import { default as item_45_91id_934JBI1qa0MgMeta } from "/builds/sites/wnhub-general-client/pages/questions/[category]/item-[id].vue?macro=true";
import { default as popularu9c8F03KmoMeta } from "/builds/sites/wnhub-general-client/pages/questions/[category]/popular.vue?macro=true";
import { default as _91category_93MyRxgsPjfTMeta } from "/builds/sites/wnhub-general-client/pages/questions/[category].vue?macro=true";
import { default as indexhL04ZDQ82tMeta } from "/builds/sites/wnhub-general-client/pages/questions/index.vue?macro=true";
import { default as popularbzwlaMwNMiMeta } from "/builds/sites/wnhub-general-client/pages/questions/popular.vue?macro=true";
import { default as questions4baAEHAd2jMeta } from "/builds/sites/wnhub-general-client/pages/questions.vue?macro=true";
import { default as terms_45conditionsZelWAqjpD9Meta } from "/builds/sites/wnhub-general-client/pages/terms-conditions.vue?macro=true";
import { default as _91id_93OUqbpMOdbNMeta } from "/builds/sites/wnhub-general-client/pages/users/[id].vue?macro=true";
import { default as basicaE4lV5nEhFMeta } from "/builds/sites/wnhub-general-client/pages/users/me/edit/basic.vue?macro=true";
import { default as settingszMj39xeeQlMeta } from "/builds/sites/wnhub-general-client/pages/users/me/edit/settings.vue?macro=true";
import { default as indexI47ilk6aIyMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/index.vue?macro=true";
import { default as lecturesJDRRZeNP9uMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/lectures.vue?macro=true";
import { default as newsjJNp6IttrFMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/news.vue?macro=true";
import { default as usersLd0miTMz9TMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/users.vue?macro=true";
import { default as favoritesUKR4dhyZzQMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites.vue?macro=true";
import { default as editKUQzd1816HMeta } from "/builds/sites/wnhub-general-client/pages/users/me/games/[id]/edit.vue?macro=true";
import { default as indexjiKdk1aCPPMeta } from "/builds/sites/wnhub-general-client/pages/users/me/games/[id]/index.vue?macro=true";
import { default as createZsyYnWHfGPMeta } from "/builds/sites/wnhub-general-client/pages/users/me/games/create.vue?macro=true";
import { default as indexcF4TtGp9JJMeta } from "/builds/sites/wnhub-general-client/pages/users/me/games/index.vue?macro=true";
import { default as indexHj1J85Qb0xMeta } from "/builds/sites/wnhub-general-client/pages/users/me/index.vue?macro=true";
import { default as _91id_93EMHUlzVAZPMeta } from "/builds/sites/wnhub-general-client/pages/users/me/orders/[id].vue?macro=true";
import { default as index8zdZ3MMsk4Meta } from "/builds/sites/wnhub-general-client/pages/users/me/orders/index.vue?macro=true";
import { default as payment_45historyMIuOED96zgMeta } from "/builds/sites/wnhub-general-client/pages/users/me/payment-history.vue?macro=true";
import { default as _91id_93JEGGvy13pJMeta } from "/builds/sites/wnhub-general-client/pages/users/me/tickets/[id].vue?macro=true";
import { default as index95nKoleiMZMeta } from "/builds/sites/wnhub-general-client/pages/users/me/tickets/index.vue?macro=true";
import { default as mevstyywGv64Meta } from "/builds/sites/wnhub-general-client/pages/users/me.vue?macro=true";
import { default as usersjYpGzagbSDMeta } from "/builds/sites/wnhub-general-client/pages/users.vue?macro=true";
import { default as indexpAMPyWudvPMeta } from "/builds/sites/wnhub-general-client/pages/video/[category]/index.vue?macro=true";
import { default as item_45_91id_932ihgVr47MyMeta } from "/builds/sites/wnhub-general-client/pages/video/[category]/item-[id].vue?macro=true";
import { default as populartibytBoylVMeta } from "/builds/sites/wnhub-general-client/pages/video/[category]/popular.vue?macro=true";
import { default as _91category_93PTROhxIezuMeta } from "/builds/sites/wnhub-general-client/pages/video/[category].vue?macro=true";
import { default as indexuOQ2Q9c4iaMeta } from "/builds/sites/wnhub-general-client/pages/video/index.vue?macro=true";
import { default as popularFTMxzZXccFMeta } from "/builds/sites/wnhub-general-client/pages/video/popular.vue?macro=true";
import { default as videoubMXtUehlZMeta } from "/builds/sites/wnhub-general-client/pages/video.vue?macro=true";
export default [
  {
    name: indexFCOxRvHzckMeta?.name ?? "url-page___ru",
    path: indexFCOxRvHzckMeta?.path ?? "/:url()-page",
    meta: indexFCOxRvHzckMeta || {},
    alias: indexFCOxRvHzckMeta?.alias || [],
    redirect: indexFCOxRvHzckMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/[url]-page/index.vue").then(m => m.default || m)
  },
  {
    name: indextMKJLElIVzMeta?.name ?? "academy-event___ru",
    path: indextMKJLElIVzMeta?.path ?? "/academy/:event()",
    meta: indextMKJLElIVzMeta || {},
    alias: indextMKJLElIVzMeta?.alias || [],
    redirect: indextMKJLElIVzMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/[event]/index.vue").then(m => m.default || m)
  },
  {
    path: indexUeUqjzPTRjMeta?.path ?? "/academy",
    children: [
  {
    name: about97LLlKQi3mMeta?.name ?? "academy-index-about___ru",
    path: about97LLlKQi3mMeta?.path ?? "about",
    meta: about97LLlKQi3mMeta || {},
    alias: about97LLlKQi3mMeta?.alias || [],
    redirect: about97LLlKQi3mMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/about.vue").then(m => m.default || m)
  },
  {
    name: indexvIIQDOO91TMeta?.name ?? "academy-index___ru",
    path: indexvIIQDOO91TMeta?.path ?? "",
    meta: indexvIIQDOO91TMeta || {},
    alias: indexvIIQDOO91TMeta?.alias || [],
    redirect: indexvIIQDOO91TMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/index.vue").then(m => m.default || m)
  },
  {
    name: myi71dIfp5b7Meta?.name ?? "academy-index-my___ru",
    path: myi71dIfp5b7Meta?.path ?? "my",
    meta: myi71dIfp5b7Meta || {},
    alias: myi71dIfp5b7Meta?.alias || [],
    redirect: myi71dIfp5b7Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/my.vue").then(m => m.default || m)
  },
  {
    name: pastwNkzrMG2MPMeta?.name ?? "academy-index-past___ru",
    path: pastwNkzrMG2MPMeta?.path ?? "past",
    meta: pastwNkzrMG2MPMeta || {},
    alias: pastwNkzrMG2MPMeta?.alias || [],
    redirect: pastwNkzrMG2MPMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/past.vue").then(m => m.default || m)
  }
],
    name: indexUeUqjzPTRjMeta?.name ?? undefined,
    meta: indexUeUqjzPTRjMeta || {},
    alias: indexUeUqjzPTRjMeta?.alias || [],
    redirect: indexUeUqjzPTRjMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93CTzcha3GaHMeta?.name ?? "auth-magic-code___ru",
    path: _91code_93CTzcha3GaHMeta?.path ?? "/auth/magic/:code()",
    meta: _91code_93CTzcha3GaHMeta || {},
    alias: _91code_93CTzcha3GaHMeta?.alias || [],
    redirect: _91code_93CTzcha3GaHMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/auth/magic/[code].vue").then(m => m.default || m)
  },
  {
    name: indexBf0gpAyrQLMeta?.name ?? "cart-event___ru",
    path: indexBf0gpAyrQLMeta?.path ?? "/cart/:event()",
    meta: indexBf0gpAyrQLMeta || {},
    alias: indexBf0gpAyrQLMeta?.alias || [],
    redirect: indexBf0gpAyrQLMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/cart/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: contactsT7Avz26Jg4Meta?.name ?? "contacts___ru",
    path: contactsT7Avz26Jg4Meta?.path ?? "/contacts",
    meta: contactsT7Avz26Jg4Meta || {},
    alias: contactsT7Avz26Jg4Meta?.alias || [],
    redirect: contactsT7Avz26Jg4Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexqOma72b19BMeta?.name ?? "event-info-event___ru",
    path: indexqOma72b19BMeta?.path ?? "/event-info/:event()",
    meta: indexqOma72b19BMeta || {},
    alias: indexqOma72b19BMeta?.alias || [],
    redirect: indexqOma72b19BMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YKXWezcl9iMeta?.name ?? "event-info-event-showcase-slug___ru",
    path: _91slug_93YKXWezcl9iMeta?.path ?? "/event-info/:event()/showcase/:slug()",
    meta: _91slug_93YKXWezcl9iMeta || {},
    alias: _91slug_93YKXWezcl9iMeta?.alias || [],
    redirect: _91slug_93YKXWezcl9iMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/showcase/[slug].vue").then(m => m.default || m)
  },
  {
    path: indexC47lifGnVYMeta?.path ?? "/event-info",
    children: [
  {
    name: indexAMsIYRm4yWMeta?.name ?? "event-info-index___ru",
    path: indexAMsIYRm4yWMeta?.path ?? "",
    meta: indexAMsIYRm4yWMeta || {},
    alias: indexAMsIYRm4yWMeta?.alias || [],
    redirect: indexAMsIYRm4yWMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/index.vue").then(m => m.default || m)
  },
  {
    name: myYf7WTaaQdjMeta?.name ?? "event-info-index-my___ru",
    path: myYf7WTaaQdjMeta?.path ?? "my",
    meta: myYf7WTaaQdjMeta || {},
    alias: myYf7WTaaQdjMeta?.alias || [],
    redirect: myYf7WTaaQdjMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/my.vue").then(m => m.default || m)
  },
  {
    name: pastRCrboUPMmdMeta?.name ?? "event-info-index-past___ru",
    path: pastRCrboUPMmdMeta?.path ?? "past",
    meta: pastRCrboUPMmdMeta || {},
    alias: pastRCrboUPMmdMeta?.alias || [],
    redirect: pastRCrboUPMmdMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/past.vue").then(m => m.default || m)
  }
],
    name: indexC47lifGnVYMeta?.name ?? undefined,
    meta: indexC47lifGnVYMeta || {},
    alias: indexC47lifGnVYMeta?.alias || [],
    redirect: indexC47lifGnVYMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nwo1ITHx9MMeta?.name ?? "iframe-event-info-event-blocks-id___ru",
    path: _91id_93nwo1ITHx9MMeta?.path ?? "/iframe/event-info/:event()/blocks/:id()",
    meta: _91id_93nwo1ITHx9MMeta || {},
    alias: _91id_93nwo1ITHx9MMeta?.alias || [],
    redirect: _91id_93nwo1ITHx9MMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/blocks/[id].vue").then(m => m.default || m)
  },
  {
    name: companies_45buttonCqvRtyl4tHMeta?.name ?? "iframe-event-info-event-companies-components-companies-button___ru",
    path: companies_45buttonCqvRtyl4tHMeta?.path ?? "/iframe/event-info/:event()/companies/components/companies-button",
    meta: companies_45buttonCqvRtyl4tHMeta || {},
    alias: companies_45buttonCqvRtyl4tHMeta?.alias || [],
    redirect: companies_45buttonCqvRtyl4tHMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-button.vue").then(m => m.default || m)
  },
  {
    name: companies_45tableVzoq5VWmDKMeta?.name ?? "iframe-event-info-event-companies-components-companies-table___ru",
    path: companies_45tableVzoq5VWmDKMeta?.path ?? "/iframe/event-info/:event()/companies/components/companies-table",
    meta: companies_45tableVzoq5VWmDKMeta || {},
    alias: companies_45tableVzoq5VWmDKMeta?.alias || [],
    redirect: companies_45tableVzoq5VWmDKMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-table.vue").then(m => m.default || m)
  },
  {
    name: indexQkzLiatZ3LMeta?.name ?? "iframe-event-info-event-companies___ru",
    path: indexQkzLiatZ3LMeta?.path ?? "/iframe/event-info/:event()/companies",
    meta: indexQkzLiatZ3LMeta || {},
    alias: indexQkzLiatZ3LMeta?.alias || [],
    redirect: indexQkzLiatZ3LMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/index.vue").then(m => m.default || m)
  },
  {
    path: indexFmwB729zM7Meta?.path ?? "/",
    children: [
  {
    path: _91category_93aVdB5jMjM0Meta?.path ?? ":category()",
    children: [
  {
    name: indexQ3gIjCZ8TiMeta?.name ?? "index-category___ru",
    path: indexQ3gIjCZ8TiMeta?.path ?? "",
    meta: indexQ3gIjCZ8TiMeta || {},
    alias: indexQ3gIjCZ8TiMeta?.alias || [],
    redirect: indexQ3gIjCZ8TiMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/index/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: popularpLjQCk3nFTMeta?.name ?? "index-category-popular___ru",
    path: popularpLjQCk3nFTMeta?.path ?? "popular",
    meta: popularpLjQCk3nFTMeta || {},
    alias: popularpLjQCk3nFTMeta?.alias || [],
    redirect: popularpLjQCk3nFTMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/index/[category]/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumnXKzkYeRFoMeta?.name ?? "index-category-premium___ru",
    path: premiumnXKzkYeRFoMeta?.path ?? "premium",
    meta: premiumnXKzkYeRFoMeta || {},
    alias: premiumnXKzkYeRFoMeta?.alias || [],
    redirect: premiumnXKzkYeRFoMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/index/[category]/premium.vue").then(m => m.default || m)
  }
],
    name: _91category_93aVdB5jMjM0Meta?.name ?? undefined,
    meta: _91category_93aVdB5jMjM0Meta || {},
    alias: _91category_93aVdB5jMjM0Meta?.alias || [],
    redirect: _91category_93aVdB5jMjM0Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/index/[category].vue").then(m => m.default || m)
  },
  {
    name: indexZRmoZ1CADzMeta?.name ?? "index___ru",
    path: indexZRmoZ1CADzMeta?.path ?? "",
    meta: indexZRmoZ1CADzMeta || {},
    alias: indexZRmoZ1CADzMeta?.alias || [],
    redirect: indexZRmoZ1CADzMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: popularji6uGXYG32Meta?.name ?? "index-popular___ru",
    path: popularji6uGXYG32Meta?.path ?? "popular",
    meta: popularji6uGXYG32Meta || {},
    alias: popularji6uGXYG32Meta?.alias || [],
    redirect: popularji6uGXYG32Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/index/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumkPwtzaXvyqMeta?.name ?? "index-premium___ru",
    path: premiumkPwtzaXvyqMeta?.path ?? "premium",
    meta: premiumkPwtzaXvyqMeta || {},
    alias: premiumkPwtzaXvyqMeta?.alias || [],
    redirect: premiumkPwtzaXvyqMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/index/premium.vue").then(m => m.default || m)
  }
],
    name: indexFmwB729zM7Meta?.name ?? undefined,
    meta: indexFmwB729zM7Meta || {},
    alias: indexFmwB729zM7Meta?.alias || [],
    redirect: indexFmwB729zM7Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/index.vue").then(m => m.default || m)
  },
  {
    path: newscWS5WuBcNCMeta?.path ?? "/news",
    children: [
  {
    path: _91category_9324I41V4sYiMeta?.path ?? ":category()",
    children: [
  {
    name: index8bftAv4JtkMeta?.name ?? "news-category___ru",
    path: index8bftAv4JtkMeta?.path ?? "",
    meta: index8bftAv4JtkMeta || {},
    alias: index8bftAv4JtkMeta?.alias || [],
    redirect: index8bftAv4JtkMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_93yGPN88Q2q1Meta?.name ?? "news-category-item-id___ru",
    path: item_45_91id_93yGPN88Q2q1Meta?.path ?? "item-:id()",
    meta: item_45_91id_93yGPN88Q2q1Meta || {},
    alias: item_45_91id_93yGPN88Q2q1Meta?.alias || [],
    redirect: item_45_91id_93yGPN88Q2q1Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: popularc2hRwihuY3Meta?.name ?? "news-category-popular___ru",
    path: popularc2hRwihuY3Meta?.path ?? "popular",
    meta: popularc2hRwihuY3Meta || {},
    alias: popularc2hRwihuY3Meta?.alias || [],
    redirect: popularc2hRwihuY3Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumE5T9S1DxDHMeta?.name ?? "news-category-premium___ru",
    path: premiumE5T9S1DxDHMeta?.path ?? "premium",
    meta: premiumE5T9S1DxDHMeta || {},
    alias: premiumE5T9S1DxDHMeta?.alias || [],
    redirect: premiumE5T9S1DxDHMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/premium.vue").then(m => m.default || m)
  }
],
    name: _91category_9324I41V4sYiMeta?.name ?? undefined,
    meta: _91category_9324I41V4sYiMeta || {},
    alias: _91category_9324I41V4sYiMeta?.alias || [],
    redirect: _91category_9324I41V4sYiMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category].vue").then(m => m.default || m)
  },
  {
    name: indexCA62VUbi3xMeta?.name ?? "news___ru",
    path: indexCA62VUbi3xMeta?.path ?? "",
    meta: indexCA62VUbi3xMeta || {},
    alias: indexCA62VUbi3xMeta?.alias || [],
    redirect: indexCA62VUbi3xMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: popularZhHeGoyDWIMeta?.name ?? "news-popular___ru",
    path: popularZhHeGoyDWIMeta?.path ?? "popular",
    meta: popularZhHeGoyDWIMeta || {},
    alias: popularZhHeGoyDWIMeta?.alias || [],
    redirect: popularZhHeGoyDWIMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumlv8SRVLlYZMeta?.name ?? "news-premium___ru",
    path: premiumlv8SRVLlYZMeta?.path ?? "premium",
    meta: premiumlv8SRVLlYZMeta || {},
    alias: premiumlv8SRVLlYZMeta?.alias || [],
    redirect: premiumlv8SRVLlYZMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/premium.vue").then(m => m.default || m)
  }
],
    name: newscWS5WuBcNCMeta?.name ?? undefined,
    meta: newscWS5WuBcNCMeta || {},
    alias: newscWS5WuBcNCMeta?.alias || [],
    redirect: newscWS5WuBcNCMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/news.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyPreT20nEW6Meta?.name ?? "privacy-policy___ru",
    path: privacy_45policyPreT20nEW6Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyPreT20nEW6Meta || {},
    alias: privacy_45policyPreT20nEW6Meta?.alias || [],
    redirect: privacy_45policyPreT20nEW6Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: pro0BKHPgHHJWMeta?.name ?? "pro___ru",
    path: pro0BKHPgHHJWMeta?.path ?? "/pro",
    meta: pro0BKHPgHHJWMeta || {},
    alias: pro0BKHPgHHJWMeta?.alias || [],
    redirect: pro0BKHPgHHJWMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/pro.vue").then(m => m.default || m)
  },
  {
    path: questions4baAEHAd2jMeta?.path ?? "/questions",
    children: [
  {
    path: _91category_93MyRxgsPjfTMeta?.path ?? ":category()",
    children: [
  {
    name: indexzpUTVcJJVkMeta?.name ?? "questions-category___ru",
    path: indexzpUTVcJJVkMeta?.path ?? "",
    meta: indexzpUTVcJJVkMeta || {},
    alias: indexzpUTVcJJVkMeta?.alias || [],
    redirect: indexzpUTVcJJVkMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_934JBI1qa0MgMeta?.name ?? "questions-category-item-id___ru",
    path: item_45_91id_934JBI1qa0MgMeta?.path ?? "item-:id()",
    meta: item_45_91id_934JBI1qa0MgMeta || {},
    alias: item_45_91id_934JBI1qa0MgMeta?.alias || [],
    redirect: item_45_91id_934JBI1qa0MgMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: popularu9c8F03KmoMeta?.name ?? "questions-category-popular___ru",
    path: popularu9c8F03KmoMeta?.path ?? "popular",
    meta: popularu9c8F03KmoMeta || {},
    alias: popularu9c8F03KmoMeta?.alias || [],
    redirect: popularu9c8F03KmoMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/popular.vue").then(m => m.default || m)
  }
],
    name: _91category_93MyRxgsPjfTMeta?.name ?? undefined,
    meta: _91category_93MyRxgsPjfTMeta || {},
    alias: _91category_93MyRxgsPjfTMeta?.alias || [],
    redirect: _91category_93MyRxgsPjfTMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category].vue").then(m => m.default || m)
  },
  {
    name: indexhL04ZDQ82tMeta?.name ?? "questions___ru",
    path: indexhL04ZDQ82tMeta?.path ?? "",
    meta: indexhL04ZDQ82tMeta || {},
    alias: indexhL04ZDQ82tMeta?.alias || [],
    redirect: indexhL04ZDQ82tMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: popularbzwlaMwNMiMeta?.name ?? "questions-popular___ru",
    path: popularbzwlaMwNMiMeta?.path ?? "popular",
    meta: popularbzwlaMwNMiMeta || {},
    alias: popularbzwlaMwNMiMeta?.alias || [],
    redirect: popularbzwlaMwNMiMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/popular.vue").then(m => m.default || m)
  }
],
    name: questions4baAEHAd2jMeta?.name ?? undefined,
    meta: questions4baAEHAd2jMeta || {},
    alias: questions4baAEHAd2jMeta?.alias || [],
    redirect: questions4baAEHAd2jMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsZelWAqjpD9Meta?.name ?? "terms-conditions___ru",
    path: terms_45conditionsZelWAqjpD9Meta?.path ?? "/terms-conditions",
    meta: terms_45conditionsZelWAqjpD9Meta || {},
    alias: terms_45conditionsZelWAqjpD9Meta?.alias || [],
    redirect: terms_45conditionsZelWAqjpD9Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: usersjYpGzagbSDMeta?.name ?? "users___ru",
    path: usersjYpGzagbSDMeta?.path ?? "/users",
    children: [
  {
    name: _91id_93OUqbpMOdbNMeta?.name ?? "users-id___ru",
    path: _91id_93OUqbpMOdbNMeta?.path ?? ":id()",
    meta: _91id_93OUqbpMOdbNMeta || {},
    alias: _91id_93OUqbpMOdbNMeta?.alias || [],
    redirect: _91id_93OUqbpMOdbNMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    path: mevstyywGv64Meta?.path ?? "me",
    children: [
  {
    name: basicaE4lV5nEhFMeta?.name ?? "users-me-edit-basic___ru",
    path: basicaE4lV5nEhFMeta?.path ?? "edit/basic",
    meta: basicaE4lV5nEhFMeta || {},
    alias: basicaE4lV5nEhFMeta?.alias || [],
    redirect: basicaE4lV5nEhFMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/basic.vue").then(m => m.default || m)
  },
  {
    name: settingszMj39xeeQlMeta?.name ?? "users-me-edit-settings___ru",
    path: settingszMj39xeeQlMeta?.path ?? "edit/settings",
    meta: settingszMj39xeeQlMeta || {},
    alias: settingszMj39xeeQlMeta?.alias || [],
    redirect: settingszMj39xeeQlMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/settings.vue").then(m => m.default || m)
  },
  {
    path: favoritesUKR4dhyZzQMeta?.path ?? "favorites",
    children: [
  {
    name: indexI47ilk6aIyMeta?.name ?? "users-me-favorites___ru",
    path: indexI47ilk6aIyMeta?.path ?? "",
    meta: indexI47ilk6aIyMeta || {},
    alias: indexI47ilk6aIyMeta?.alias || [],
    redirect: indexI47ilk6aIyMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: lecturesJDRRZeNP9uMeta?.name ?? "users-me-favorites-lectures___ru",
    path: lecturesJDRRZeNP9uMeta?.path ?? "lectures",
    meta: lecturesJDRRZeNP9uMeta || {},
    alias: lecturesJDRRZeNP9uMeta?.alias || [],
    redirect: lecturesJDRRZeNP9uMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/lectures.vue").then(m => m.default || m)
  },
  {
    name: newsjJNp6IttrFMeta?.name ?? "users-me-favorites-news___ru",
    path: newsjJNp6IttrFMeta?.path ?? "news",
    meta: newsjJNp6IttrFMeta || {},
    alias: newsjJNp6IttrFMeta?.alias || [],
    redirect: newsjJNp6IttrFMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/news.vue").then(m => m.default || m)
  },
  {
    name: usersLd0miTMz9TMeta?.name ?? "users-me-favorites-users___ru",
    path: usersLd0miTMz9TMeta?.path ?? "users",
    meta: usersLd0miTMz9TMeta || {},
    alias: usersLd0miTMz9TMeta?.alias || [],
    redirect: usersLd0miTMz9TMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/users.vue").then(m => m.default || m)
  }
],
    name: favoritesUKR4dhyZzQMeta?.name ?? undefined,
    meta: favoritesUKR4dhyZzQMeta || {},
    alias: favoritesUKR4dhyZzQMeta?.alias || [],
    redirect: favoritesUKR4dhyZzQMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites.vue").then(m => m.default || m)
  },
  {
    name: editKUQzd1816HMeta?.name ?? "users-me-games-id-edit___ru",
    path: editKUQzd1816HMeta?.path ?? "games/:id()/edit",
    meta: editKUQzd1816HMeta || {},
    alias: editKUQzd1816HMeta?.alias || [],
    redirect: editKUQzd1816HMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexjiKdk1aCPPMeta?.name ?? "users-me-games-id___ru",
    path: indexjiKdk1aCPPMeta?.path ?? "games/:id()",
    meta: indexjiKdk1aCPPMeta || {},
    alias: indexjiKdk1aCPPMeta?.alias || [],
    redirect: indexjiKdk1aCPPMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createZsyYnWHfGPMeta?.name ?? "users-me-games-create___ru",
    path: createZsyYnWHfGPMeta?.path ?? "games/create",
    meta: createZsyYnWHfGPMeta || {},
    alias: createZsyYnWHfGPMeta?.alias || [],
    redirect: createZsyYnWHfGPMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/create.vue").then(m => m.default || m)
  },
  {
    name: indexcF4TtGp9JJMeta?.name ?? "users-me-games___ru",
    path: indexcF4TtGp9JJMeta?.path ?? "games",
    meta: indexcF4TtGp9JJMeta || {},
    alias: indexcF4TtGp9JJMeta?.alias || [],
    redirect: indexcF4TtGp9JJMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexHj1J85Qb0xMeta?.name ?? "users-me___ru",
    path: indexHj1J85Qb0xMeta?.path ?? "",
    meta: indexHj1J85Qb0xMeta || {},
    alias: indexHj1J85Qb0xMeta?.alias || [],
    redirect: indexHj1J85Qb0xMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EMHUlzVAZPMeta?.name ?? "users-me-orders-id___ru",
    path: _91id_93EMHUlzVAZPMeta?.path ?? "orders/:id()",
    meta: _91id_93EMHUlzVAZPMeta || {},
    alias: _91id_93EMHUlzVAZPMeta?.alias || [],
    redirect: _91id_93EMHUlzVAZPMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: index8zdZ3MMsk4Meta?.name ?? "users-me-orders___ru",
    path: index8zdZ3MMsk4Meta?.path ?? "orders",
    meta: index8zdZ3MMsk4Meta || {},
    alias: index8zdZ3MMsk4Meta?.alias || [],
    redirect: index8zdZ3MMsk4Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/orders/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45historyMIuOED96zgMeta?.name ?? "users-me-payment-history___ru",
    path: payment_45historyMIuOED96zgMeta?.path ?? "payment-history",
    meta: payment_45historyMIuOED96zgMeta || {},
    alias: payment_45historyMIuOED96zgMeta?.alias || [],
    redirect: payment_45historyMIuOED96zgMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/payment-history.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JEGGvy13pJMeta?.name ?? "users-me-tickets-id___ru",
    path: _91id_93JEGGvy13pJMeta?.path ?? "tickets/:id()",
    meta: _91id_93JEGGvy13pJMeta || {},
    alias: _91id_93JEGGvy13pJMeta?.alias || [],
    redirect: _91id_93JEGGvy13pJMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/tickets/[id].vue").then(m => m.default || m)
  },
  {
    name: index95nKoleiMZMeta?.name ?? "users-me-tickets___ru",
    path: index95nKoleiMZMeta?.path ?? "tickets",
    meta: index95nKoleiMZMeta || {},
    alias: index95nKoleiMZMeta?.alias || [],
    redirect: index95nKoleiMZMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/tickets/index.vue").then(m => m.default || m)
  }
],
    name: mevstyywGv64Meta?.name ?? undefined,
    meta: mevstyywGv64Meta || {},
    alias: mevstyywGv64Meta?.alias || [],
    redirect: mevstyywGv64Meta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me.vue").then(m => m.default || m)
  }
],
    meta: usersjYpGzagbSDMeta || {},
    alias: usersjYpGzagbSDMeta?.alias || [],
    redirect: usersjYpGzagbSDMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/users.vue").then(m => m.default || m)
  },
  {
    path: videoubMXtUehlZMeta?.path ?? "/video",
    children: [
  {
    path: _91category_93PTROhxIezuMeta?.path ?? ":category()",
    children: [
  {
    name: indexpAMPyWudvPMeta?.name ?? "video-category___ru",
    path: indexpAMPyWudvPMeta?.path ?? "",
    meta: indexpAMPyWudvPMeta || {},
    alias: indexpAMPyWudvPMeta?.alias || [],
    redirect: indexpAMPyWudvPMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_932ihgVr47MyMeta?.name ?? "video-category-item-id___ru",
    path: item_45_91id_932ihgVr47MyMeta?.path ?? "item-:id()",
    meta: item_45_91id_932ihgVr47MyMeta || {},
    alias: item_45_91id_932ihgVr47MyMeta?.alias || [],
    redirect: item_45_91id_932ihgVr47MyMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: populartibytBoylVMeta?.name ?? "video-category-popular___ru",
    path: populartibytBoylVMeta?.path ?? "popular",
    meta: populartibytBoylVMeta || {},
    alias: populartibytBoylVMeta?.alias || [],
    redirect: populartibytBoylVMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/popular.vue").then(m => m.default || m)
  }
],
    name: _91category_93PTROhxIezuMeta?.name ?? undefined,
    meta: _91category_93PTROhxIezuMeta || {},
    alias: _91category_93PTROhxIezuMeta?.alias || [],
    redirect: _91category_93PTROhxIezuMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category].vue").then(m => m.default || m)
  },
  {
    name: indexuOQ2Q9c4iaMeta?.name ?? "video___ru",
    path: indexuOQ2Q9c4iaMeta?.path ?? "",
    meta: indexuOQ2Q9c4iaMeta || {},
    alias: indexuOQ2Q9c4iaMeta?.alias || [],
    redirect: indexuOQ2Q9c4iaMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/index.vue").then(m => m.default || m)
  },
  {
    name: popularFTMxzZXccFMeta?.name ?? "video-popular___ru",
    path: popularFTMxzZXccFMeta?.path ?? "popular",
    meta: popularFTMxzZXccFMeta || {},
    alias: popularFTMxzZXccFMeta?.alias || [],
    redirect: popularFTMxzZXccFMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/popular.vue").then(m => m.default || m)
  }
],
    name: videoubMXtUehlZMeta?.name ?? undefined,
    meta: videoubMXtUehlZMeta || {},
    alias: videoubMXtUehlZMeta?.alias || [],
    redirect: videoubMXtUehlZMeta?.redirect || undefined,
    component: () => import("/builds/sites/wnhub-general-client/pages/video.vue").then(m => m.default || m)
  }
]