export const useLogError = (error: any, throwNext = false) => {
  // on SSR after error, we unfortunately getting nuxtApp unavailable exception so we simply rely on env mode here

  if (process.env.NODE_ENV === 'production') {
    console.error('failed to capture exception', error)
  }

  if (throwNext) {
    throw error
  }
}
