export const appHead = {"meta":[{"charset":"utf-8"},{"property":"og:site_name","content":"WN Hub"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"}],"link":[],"style":[],"script":[],"noscript":[],"titleTemplate":"%s | WN Hub"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'