<template>
  <main
    class="h-screen flex flex-col overflow-hidden bg-white justify-center items-center"
  >
    <div class="text-center">
      <h1 class="text-xl font-black mb-2">
        <template v-if="!isNetworkIssue">
          {{ $t(`errors.${unifiedCode}.title`) }}
        </template>
        <template v-else>
          {{ $t(`errors.network.title`) }}
        </template>
      </h1>
      <p class="text-gray-500 mb-4">
        <template v-if="!isNetworkIssue">
          {{ $t(`errors.${unifiedCode}.text`) }}
        </template>
        <template v-else>
          {{ $t(`errors.network.text`) }}
        </template>
      </p>
      <template v-if="isNetworkIssue">
        <BaseButton look="ghost" size="sm" @click="onReload">
          {{ $t('errors.network.button') }}
        </BaseButton>
      </template>
      <template v-else>
        <BaseLocaleLink to="/">
          <BaseButton look="ghost" size="sm">
            {{ $t('errors.button') }}
          </BaseButton>
        </BaseLocaleLink>
      </template>
    </div>
  </main>
</template>

<script setup lang="ts">
const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
})

const supportedCodes = [401, 404]
const defaultCode = 500
const isConnected = ref(true)

onBeforeMount(() => {
  try {
    isConnected.value = true
  } catch (error: any) {
    useLogError(error)
  }
})

const unifiedCode = computed(() => {
  const errorCode = Number(props.error?.statusCode)
  return !Number.isNaN(errorCode) && supportedCodes.includes(errorCode)
    ? errorCode
    : defaultCode
})

const isNetworkIssue = computed(
  () => !isConnected.value && unifiedCode.value === defaultCode
)

function onReload() {
  window.location.reload()
}
</script>
