import type { NuxtError } from 'nuxt/dist/app/composables'

const skipKey = 'skip-capture'
// use createError with a fatal flag to trigger error view instead of showError (recommended by nuxt team)
export const useCreateScreenError = (data: Partial<NuxtError>) => {
  const defaultErrorObject = {
    statusCode: 404,
    fatal: true,
  }

  const errorObject = {
    ...defaultErrorObject,
    ...data,
    message: (data.message || 'no error message') + '; ' + skipKey,
  }

  console.log('createError', errorObject)

  return createError(errorObject)
}

export const useSkipKey = () => skipKey
