import { useAuth } from '~/stores/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  const profileRoute = '/users/me/edit/basic'
  const authStore = useAuth()
  // here is crucial to load authCookie before checking the Auth as it is the entry point for the App

  // TODO: add to issues, middleware runs before any components, even app.vue, so it is crucial moment here to load profile for app
  if (authStore.auth && !authStore.profile) {
    try {
      await authStore.fetchProfile()
    } catch (error: any) {
      if (error.status === 401) {
        useLogError(new Error('Failed to fetch profile (invalid auth cookie)'))
        authStore.logout()
      } else {
        // for some reason we don't get throwed error in any error handler during SSR
        // so we directly logging it with throw next to render error page
        useLogError(error, true)
      }
    }
  }

  if (
    !to.fullPath.includes(profileRoute) &&
    !authStore.isProfileCompleted &&
    authStore.auth
  ) {
    return navigateTo(useLocaleRoute()(profileRoute))
  }
})
