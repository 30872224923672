import { defineStore } from 'pinia'
import type { UserProfile } from '~/models/users'
import { OrderStatus, OrderItemActivationStatus } from '~/models/tickets'

import type {
  TicketsOrder,
  TicketsRecord,
  TicketsOrderResponse,
  TicketDetails,
  UserTicket,
  UpgradeRequestData,
  TicketForUpgrade,
  Payment,
} from '~/models/tickets'

interface TicketsState {
  tickets: TicketsRecord
  ticketsDetails: TicketDetails[]
  order: TicketsOrderResponse | null
  eventId: number | null
  ticketsEventId: number | null
  ordersByEventId: TicketsOrderResponse[]
  userTickets: UserTicket[]
  ticketsForUpgrade: TicketForUpgrade[]
  itemsToActivateCount: {
    order: number
    subscription: number
  } | null
  payments: Payment[]
}

export const useTickets = defineStore('tickets', () => {
  const baseURL = useRuntimeConfig().public.gatewayApi

  const state = reactive<TicketsState>({
    tickets: [],
    ticketsDetails: [],
    order: null,
    eventId: null,
    ticketsEventId: null,
    ordersByEventId: [],
    userTickets: [],
    ticketsForUpgrade: [],
    itemsToActivateCount: null,
    payments: [],
  })

  async function fetchTicketList(eventId?: number): Promise<any> {
    if (
      !eventId ||
      (eventId === state.ticketsEventId && Object.keys(state.tickets).length)
    ) {
      return
    }

    try {
      const { data } = await useKrakenFetch<{ data: TicketsRecord }>(
        `/ticket/list/${eventId}`,
        {
          baseURL,
        }
      )
      state.ticketsEventId = eventId
      state.tickets = data
    } catch (error: any) {
      if (error.statusCode === 404) {
        state.eventId = eventId
        state.tickets = []
      } else {
        throw error
      }
    }
  }

  async function createOrder(order: TicketsOrder) {
    const { data } = await useAuthFetch<{ data: TicketsOrderResponse }>(
      'orders',
      {
        method: 'POST',
        baseURL,
        body: order,
      }
    )
    return data
  }

  async function checkPromocode(
    code: string,
    eventId: number,
    tickets: { id: number; count: number }[]
  ) {
    const params: { [key: string]: string | number } = {
      code,
      eventId,
    }
    tickets.forEach((entry) => {
      params[`tickets[${entry.id}]`] = String(entry.count)
    })

    const { data } = await useKrakenFetch<{
      data: { discount: { [key: string]: number } }
    }>('promo/discount', {
      baseURL,
      params,
    })

    return data.discount
  }

  async function fetchOrder(
    orderId: number,
    ignoreSavedData = false
  ): Promise<void> {
    if (state.order?.id === orderId && !ignoreSavedData) {
      return
    }

    const { data } = await useAuthFetch<{ data: TicketsOrderResponse }>(
      `orders/${orderId}`,
      {
        baseURL,
      }
    )

    state.order = data
  }

  async function fetchItemsToActivateCount() {
    if (state.itemsToActivateCount) {
      return
    }

    interface DataInterface {
      order: {
        data: {
          count: number
        }
      }
      subscription: {
        data: {
          count: number
        }
      }
    }

    const { order, subscription } = await useAuthFetch<DataInterface>(
      'order-items/not-active-count',
      {
        baseURL,
      }
    )

    state.itemsToActivateCount = {
      order: order.data.count,
      subscription: subscription.data.count,
    }
  }

  const resetItemsToActivateCount = () => {
    state.itemsToActivateCount = null
  }

  const initOrdersList = () => {
    const path = '/orders'
    const list = useInfinityList<TicketsOrderResponse>(path, baseURL)

    list.state.params = { per_page: 6 }
    return list
  }

  const ordersList = initOrdersList() // init on start to avoid SSR state dehydration issues

  function formatPrice(
    value: number,
    currency = 'USD',
    language = 'en-US',
    fractionDigits = 2
  ) {
    const formatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: fractionDigits,
    })

    return formatter.format(value)
  }

  const countOrderItemsToActivate = (order: TicketsOrderResponse) => {
    if (order.status === OrderStatus.Created) {
      return 0
    }

    return order.items.reduce((acc, item) => {
      if (item.activation_status !== OrderItemActivationStatus.Activated) {
        acc += 1
      }
      return acc
    }, 0)
  }

  async function getOrderPaymentLink(orderId: number): Promise<string> {
    const { data } = await useAuthFetch<{ data: { payment_link: string } }>(
      `orders/${orderId}/payment-link`,
      {
        baseURL,
        method: 'POST',
      }
    )

    return data.payment_link
  }

  async function activateOrderItem(
    itemId: number,
    memberData: Partial<UserProfile>
  ): Promise<void> {
    await useAuthFetch<{ data: string }>(`order-items/${itemId}/activate`, {
      method: 'POST',
      baseURL,
      body: memberData,
    })
  }

  async function fetchTicketsForUpgrade(ticketId: number): Promise<void> {
    const userTicket = Object.values(state.ticketsForUpgrade).find(
      (ticket) => ticket.is_ticket_of_user
    )

    if (userTicket && userTicket.hub_ticket_id === ticketId) {
      return
    }

    const { data } = await useAuthFetch<{ data: TicketForUpgrade[] }>(
      'ticket/for-upgrade',
      {
        baseURL,
        params: {
          hubTicketId: ticketId,
        },
      }
    )

    state.ticketsForUpgrade = data
  }

  async function fetchUserTicketList(): Promise<void> {
    if (state.userTickets.length) {
      return
    }

    const { data } = await useAuthFetch<{ data: UserTicket[] }>('tickets/me', {
      baseURL,
    })

    state.userTickets = data
  }

  async function getUpgradePaymentLink(
    upgradeData: UpgradeRequestData
  ): Promise<string> {
    const { data } = await useAuthFetch<{
      data: { wnconf_payment_url: string }
    }>('/upgrade-tickets', {
      baseURL,
      method: 'POST',
      body: upgradeData,
    })

    return data.wnconf_payment_url
  }

  async function fetchOrdersByEventId(eventId: number): Promise<void> {
    if (
      !eventId ||
      (state.eventId === eventId && state.ordersByEventId.length)
    ) {
      return
    }

    const { data } = await useAuthFetch<{ data: TicketsOrderResponse[] }>(
      'orders',
      {
        baseURL,
        params: {
          'filter[event_id]': eventId,
        },
      }
    )

    state.eventId = eventId
    state.ordersByEventId = data
  }

  async function fetchTicketListDetails(eventId?: number) {
    if (
      !eventId ||
      (state.ticketsDetails.length &&
        state.eventId === state.ticketsDetails[0].event_id)
    ) {
      return
    }

    const { data } = await useKrakenFetch<{ data: TicketDetails[] }>(
      `${eventId}/tickets`,
      {
        baseURL,
      }
    )

    state.eventId = eventId
    state.ticketsDetails = data
  }

  async function fetchPaymentsList() {
    if (state.payments.length) {
      return
    }

    const { data } = await useAuthFetch<{ data: Payment[] }>(
      'payments/history',
      {
        baseURL,
      }
    )

    state.payments = data
  }

  function resetPaymentsList() {
    state.payments = []
  }

  return {
    order: toRef(state, 'order'),
    userTickets: toRef(state, 'userTickets'),
    ordersByEventId: toRef(state, 'ordersByEventId'),
    ticketsDetails: toRef(state, 'ticketsDetails'),
    ticketsForUpgradeMap: toRef(state, 'ticketsForUpgrade'),
    ticketsForUpgrade: computed(() =>
      Object.keys(state.ticketsForUpgrade).map(
        (id) => state.ticketsForUpgrade[Number(id)]
      )
    ),
    itemsToActivateCount: toRef(state, 'itemsToActivateCount'),
    payments: toRef(state, 'payments'),
    orders: ordersList.state,
    getOrders: ordersList.getPage,
    getNext: ordersList.nextPage,
    allOrdersLoaded: ordersList.allRecordsLoaded,
    reset: ordersList.reset,
    fetchTicketList,
    createOrder,
    checkPromocode,
    ticketsMap: toRef(state, 'tickets'),
    tickets: computed(() =>
      Object.keys(state.tickets).map((id) => state.tickets[Number(id)])
    ),
    fetchOrder,
    formatPrice,
    countOrderItemsToActivate,
    getOrderPaymentLink,
    activateOrderItem,
    fetchItemsToActivateCount,
    resetItemsToActivateCount,
    fetchTicketsForUpgrade,
    fetchUserTicketList,
    getUpgradePaymentLink,
    fetchOrdersByEventId,
    fetchTicketListDetails,
    fetchPaymentsList,
    resetPaymentsList,

    // we should return state data even if we don't use it.
    // Otherwise pinia doesn't save it between server and client side
    currentEventId: toRef(state, 'eventId'),
  }
})
