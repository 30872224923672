declare let window: {
  ym: any
}

function loadScript() {
  return new Promise((resolve, reject) => {
    try {
      ;(function (
        m: any,
        e: Document,
        t: string,
        r: string,
        i: string,
        k?: HTMLScriptElement,
        a?: Element
      ) {
        m[i] =
          m[i] ||
          function () {
            // eslint-disable-next-line prefer-rest-params
            ;(m[i].a = m[i].a || []).push(arguments)
          }
        m[i].l = 1 * Number(new Date())
        for (let j = 0; j < document.scripts.length; j++) {
          if (document.scripts[j].src === r) {
            return
          }
        }
        k = e.createElement(t) as HTMLScriptElement
        a = e.getElementsByTagName(t)[0]
        k.async = true
        k.src = r
        a.parentNode?.insertBefore(k, a)
      })(
        window,
        document,
        'script',
        'https://mc.yandex.ru/metrika/tag.js',
        'ym'
      )
      resolve({ success: true })
    } catch (error: any) {
      reject(error)
    }
  })
}

function debug(message: string, args?: any) {
  console.log(`[Metrika] ${message}`, args)
}

export async function createMetrika(tag: string, isDebug = false) {
  try {
    await loadScript()
  } catch (error: any) {
    console.log('Failed to load Metrika script', error)
  }

  if (!isDebug) {
    window.ym(tag, 'init', {
      accurateTrackBounce: true,
      clickmap: true,
      defer: false,
      ecommerce: false,
      params: [],
      userParams: {},
      trackHash: false,
      trackLinks: true,
      type: 0,
      webvisor: false,
      triggerEvent: false,
    })

    console.log('analytics: yandex metrika tag successfully inited')

    return (...args: any[]) => window.ym(tag, ...args)
  } else {
    return (...args: any[]) => debug('ym:', args)
  }
}
